import { makeConstant } from '../helper/typescript';

import type { stringOrNumber } from 'types';

export default makeConstant({
  ACHIEVEMENTS: '/achievements',
  DISCOVERY: '/discovery',
  DISCOVERY_NEW: '/discovery/new',
  DISCOVERY_ID_URL_FUNC: (templateId: stringOrNumber) => `/discovery/${templateId}`,
  DISCOVERY_ID_UPDATE_URL_FUNC: (templateId: stringOrNumber) => `/discovery/${templateId}/update`,
  GROUPS: '/groups',
  GROUPS_ARCHIVED: '/groups#archived',
  GROUPS_ID_FUNC: (groupId: stringOrNumber) => `/groups/${groupId}`,
  GROUPS_ID_MEMBERS_FUNC: (groupId: stringOrNumber) => `/groups/${groupId}/members`,
  GROUPS_ID_MEMBERS_ID_FUNC: (groupId: stringOrNumber, memberId: stringOrNumber) =>
    `/groups/${groupId}/members/${memberId}`,
  GROUPS_ID_TOPICS_FUNC: (groupId: stringOrNumber) => `/groups/${groupId}/topics`,
  GROUPS_ID_TOPICS_ID_CAPTIONS_FUNC: (groupId: stringOrNumber, topicId: stringOrNumber) =>
    `/groups/${groupId}/topics/${topicId}/captions`,
  GROUPS_ID_TOPICS_ID_RESPONSES_FUNC: (groupId: stringOrNumber, topicId: stringOrNumber) =>
    `/groups/${groupId}/topics/${topicId}/responses`,
  GROUPS_ID_TOPICS_ID_RESPONSES_ID_CAPTIONS_FUNC: (
    groupId: stringOrNumber,
    topicId: stringOrNumber,
    responseId: stringOrNumber,
  ) => `/groups/${groupId}/topics/${topicId}/responses/${responseId}/captions`,
  GROUPS_ID_TOPICS_ID_RESPONSES_ID_COMMENTS_FUNC: (
    groupId: stringOrNumber,
    topicId: stringOrNumber,
    responseId: stringOrNumber,
  ) => `/groups/${groupId}/topics/${topicId}/responses/${responseId}/comments`,
  GROUPS_ID_TOPICS_ID_RESPONSES_ID_COMMENTS_ID_FUNC: (
    groupId: stringOrNumber,
    topicId: stringOrNumber,
    responseId: stringOrNumber,
    commentId: stringOrNumber,
  ) => `/groups/${groupId}/topics/${topicId}/responses/${responseId}/comments/${commentId}`,
  GROUPS_ID_TOPICS_ID_RESPONSES_ID_FUNC: (
    groupId: stringOrNumber,
    topicId: stringOrNumber,
    responseId: stringOrNumber,
  ) => `/groups/${groupId}/topics/${topicId}/responses/${responseId}`,
  GROUPS_ID_TOPICS_ID_RESPONSES_ID_HASH_FUNC: (
    groupId: stringOrNumber,
    topicId: stringOrNumber,
    responseId: stringOrNumber,
    hash: stringOrNumber,
  ) => `/groups/${groupId}/topics/${topicId}/responses/${responseId}#${hash}`,
  GROUPS_ID_TOPICS_ID_UPDATE_FUNC: (groupId: stringOrNumber, topicId?: stringOrNumber) =>
    `/groups/${groupId}/topics/${topicId}/update`,
  GROUPS_ID_TOPICS_NEW_FUNC: (groupId: stringOrNumber) => `/groups/${groupId}/topics/new`,
  GROUPS_ID_UPDATE_FUNC: (groupId: stringOrNumber) => `/groups/${groupId}/update`,
  GROUPS_NEW: '/groups/new',
  HOME: '/',
  MIXTAPES: '/mixtapes',
  MIXTAPES_NEW: '/mixtapes/new',
  MIXTAPES_LOGIN_TOKEN_FUNC: (mixtapeVanity: string) => `/mixtapes/login/${mixtapeVanity}`,
  MIXTAPES_PLAYBACK_FUNC: (mixtapeId: stringOrNumber, mixtapeResponseId: stringOrNumber) =>
    `/mixtapes/${mixtapeId}/${mixtapeResponseId}`,
  MIXTAPE_ROOT_URL_FUNC: (rootUrl: string) => `${rootUrl}/mixtapes/`,
  MIXTAPES_ID_URL_FUNC: (mixtapeId: stringOrNumber, rootUrl?: string) => {
    return rootUrl ? `${rootUrl}/mixtapes/${mixtapeId}` : `/mixtapes/${mixtapeId}`;
  },
  MIXTAPES_ID_UPDATE_FUNC: (mixtapeId: stringOrNumber) => `/mixtapes/${mixtapeId}/update`,
  MY_VIDEOS_PLAYBACK_FUNC: (responseId: stringOrNumber) => `/my-videos/${responseId}`,
  MY_VIDEOS_PLAYBACK_CAPTIONS_FUNC: (responseId: stringOrNumber) => `/my-videos/${responseId}/captions`,
  MY_VIDEOS_ID_COMMENTS_FUNC: (responseId: stringOrNumber) => `/my-videos/${responseId}/comments`,
  MY_VIDEOS_ID_COMMENTS_ID_FUNC: (responseId: stringOrNumber, commentId: stringOrNumber) =>
    `/my-videos/${responseId}/comments/${commentId}`,
  MY_VIDEOS: '/my-videos',
  SETTINGS: '/settings',
  SETTINGS_ACCOUNT: '/settings/account',
  SETTINGS_DEFAULT_TOPIC: '/settings/default-topic',
  SETTINGS_DISCOVERY_PROFILE: '/settings/discovery-profile',
  SETTINGS_INTEGRATIONS: '/settings/integrations',
  GROUPS_ID_GROUPS_NAME_VANITY_TOKEN_BADGES_FUNC: (
    groupId: stringOrNumber,
    groupName: string,
    vanityToken: stringOrNumber,
  ) => `/qr/badges?groupid=${groupId}&groupName=${encodeURIComponent(groupName)}&vanityToken=${vanityToken}`,
  GROUPS_ID_GROUPS_NAME_VANITY_TOKEN_SINGLE_BADGE_FUNC: (
    groupId: stringOrNumber,
    groupName: string,
    vanityToken: stringOrNumber,
    uid: string,
  ) => `/qr/badges?groupid=${groupId}&groupName=${encodeURIComponent(groupName)}&vanityToken=${vanityToken}&uid=${uid}`,
  GROUPS_ID_TOPIC_RESPONSE_BADGES_FUNC: (
    groupId: stringOrNumber,
    topicId: stringOrNumber,
    totalResponses: stringOrNumber,
    topicName: string,
  ) => `/qr/badges?groupid=${groupId}&topicid=${topicId}&totalResponses=${totalResponses}&topicName=${topicName}`,
  VANITY_FUNC: (groupdId: stringOrNumber) => `/${groupdId}`,
  VANITY_RTJ_FUNC: (groupdId: stringOrNumber) => `/${groupdId}/rtj`,
  VANITY_USERNAMETOKEN_FUNC: (vanityToken: stringOrNumber, userNameToken: stringOrNumber) =>
    `/${vanityToken}?usernameToken=${userNameToken}`,
});
